/*
SCREENSIZE
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 25, 2022
UPDATE DATE: May 25, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ScreenSize | Check your screen size!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;

      > div{
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;

        > span{
          display: block;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
        }
      }
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > div{
    > a{
      display: block;
      float: left;
      width: 164px;
      padding: 5px 0;

      > svg{
        display: block;
        height: 40px;
        width: 100%;
      }
    }

    > ul{
      margin: -5px;
      margin-left: 169px;
      overflow: auto;
  
      > li{
        position: relative;
        padding: 5px;
        float: left;
        box-sizing: border-box;
  
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 25%;
        }
  
        label{
          position: absolute;
          pointer-events: none;
          top: 1px;
          left: 16px;
          background: #FFF;
          padding: 0 5px;
          font-size: 11px;
          line-height: 11px;
        }
  
        .unit{
          position: absolute;
          pointer-events: none;
          top: 5px;
          right: 5px;
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          background: #CCC;
          color: #FFF;
          border-radius: 0 5px 5px 0;
          font-size: 14px;
          font-weight: bold;
          transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;
        }
  
        input{
          border: 1px solid #CCC;
          height: 40px;
          border-radius: 5px;
          padding: 4px 55px 0 15px;
          background: #FFF;
          font-size: 14px;
  
          &.no-unit{
            padding: 4px 15px 0 15px;
          }
  
          &:hover,
          &:focus{
            border: 1px solid #999;
  
            ~ .unit{
              background: #999;
            }
          }
        }
      }
    }
  }
}

.the-grid{
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  background: #EEE;
  box-sizing: border-box;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &.color-width{
    background: rgba(248, 221, 92, .75);
  }

  .mar-left,
  .mar-right{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #EEE;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;

    &.color-mar{
      background: rgba(1, 163, 104, .75);
    }

    &.color-hide{
      background: rgba(1, 163, 104, 0);
    }
  }
  
  .mar-right{
    left: auto;
    right: 0;
  }

  > .pixel{
    position: absolute;
    top: 50%;
    transform: translate(0, -45%);
    left: 0;
    right: 0;
    z-index: 1;

    > div{
      text-align: center;
      background: rgba(#333, .5);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      padding: 5px 10px;
      font-size: 11px;
      line-height: 15px;
      color: #FFF;
      z-index: 1;
      text-align: right;
      box-sizing: border-box;
      white-space: nowrap;
      transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;
  
      &:hover{
        padding: 10px 5px;
        background: rgba(#333, .75);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
      }
  
      > span{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 12px;
        line-height: 16px;
      }
    }

    > ul{
      pointer-events: none;
  
      > li{
        margin: 5px 0;
        box-sizing: border-box;
        transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;
  
        > div{
          position: relative;
          pointer-events: all;
          text-align: right;
          color: #FFF;
          // padding: 5px 10px 5px 30px;
          padding: 5px 10px;
          font-size: 11px;
          line-height: 15px;
          background: rgba(#333, .5);
          backdrop-filter: blur(3px);
          -webkit-backdrop-filter: blur(3px);
          transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;
  
          &:hover{
            padding: 10px 10px 10px 30px;
            background: rgba(#333, .75);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
          }
  
          > span{
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  > ul.column{
    overflow: hidden;
    position: relative;
    z-index: 0;
    transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
  
    > li{
      float: left;
      box-sizing: border-box;
  
      > div{
        position: relative;
        // background: rgba(#333, .5);
        background: #999;
        color: #FFF;
        height: 100vh;
        transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;

        &:hover{
          // background: rgba(#333, .75);
          background: #666;
        }

        > div{
          position: absolute;
          top: 90px;
          left: 0;
          right: 0;
          text-align: center;
          // font-size: 36px;
          // line-height: 36px;
          font-weight: 100;
          transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;

          > sup{
            // font-size: 20px;
            // line-height: 20px;
            font-weight: normal;
            // top: -12px;
            left: 2px;
            transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -webkit-transition: all 200ms ease-in-out;
          }
        }
      }
    }

    &.color-column{
      > li{
        > div{
          background: rgba(207, 87, 150, .5);

          &:hover{
            background: rgba(207, 87, 150, .75);
          }
        }
      }
    }

    &.color-gutter{
      background: rgba(113, 217, 226, .5);
    }
  }
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  z-index: 3;

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  header{
    > div{
      > ul{
        > li{
          &:nth-child(1){
            width: 18%;
          }

          &:nth-child(2){
            width: 23%;
          }

          &:nth-child(3){
            width: 23%;
          }

          &:nth-child(4){
            width: 36%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  footer{
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > div{
      > ul{
        > li{
          &:nth-child(1){
            width: 17%;
          }

          &:nth-child(2){
            width: 23%;
          }

          &:nth-child(3){
            width: 23%;
          }

          &:nth-child(4){
            width: 37%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }

  header{
    > div{
      > a{
        float: none;
        margin: 0 auto;
        padding: 0 0 15px 0;

        > svg{
          height: 35px;
        }
      }

      > ul{
        margin: -5px;

        > li{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4){
            width: 50%;
          }
        }
      }
    }
  }

  .the-grid{
    // > ul.pixel{
    //   bottom: 115px;
    // }

    // > div{
    //   bottom: 90px;
    // }

    > ul.column{
      > li{
        > div{
          > div{
            top: 190px;
          }
        }
      }
    }
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/